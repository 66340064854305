<template>
    <v-app-bar elevation="1" class="confiance-dark-background">
      <v-btn icon variant="text" elevation="0" class="ml-4" size="small">
        <img src="../../assets/icon/Confiance_Icon.svg"/>
      </v-btn>
      <v-divider vertical class="border-opacity-25 ml-4" :thickness="2"/>
        <h4 v-if="!hideCardMenu" class="text-style-classic text-white text-center w-100 ms-16">{{ projectName }} - {{ projectVersion }}</h4>
        <h4 v-else class="text-style-classic text-white text-center w-100">TWE-Companion</h4>
      <v-divider v-if="!hideCardMenu" vertical class="border-opacity-25 mr-2" :thickness="2" />
      <v-col v-if="!hideCardMenu">
        <v-badge :content="unseenNotif" dot color="success" v-if="unseenNotif == 0">
          <v-icon v-if="!hideCardMenu" flat icon="mdi-bell" color="white" size="small"
            @click="openRightSlider('notification')"/>
        </v-badge>
        <v-badge :content="unseenNotif" color="error" v-else>
          <v-icon flat icon="mdi-bell" color="white" size="small"
            @click="openRightSlider('notification')"/>
        </v-badge>
        <v-tooltip activator="parent" location="top">Notification</v-tooltip>
      </v-col>
      <v-col v-if="!hideCardMenu">
        <v-icon  flat icon="mdi-chat-question-outline" color="white" size="small"
          @click="openRightSlider('glossary')"/>
        <v-tooltip activator="parent" location="top">Glossary</v-tooltip>
      </v-col>
      <v-divider vertical class="border-opacity-25 ml-2 mr-2" :thickness="2" />
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-col>
            <v-icon color="white" icon="mdi-account" v-bind="props" size="small"/>
          </v-col>
        </template>
        <v-list class="text-center">
          <v-list-item-title class="text-style-classic">
              {{ name }}
            </v-list-item-title>
          <v-list-item>
            <v-list-item-title>
              <a href='https://git.irt-systemx.fr/confianceai/ec_1/fa26/companion/companion/-/issues/new' target="_blank">Report issue</a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=fEy778GKbUG_MHkb6GqtCw494Rz4Fe5Du6YlcnD5tuJUMDJRSDRYUDdTWUg5RVUzWTk1S09KM0hJWC4u' target="_blank">Access survey</a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-style-classic">Licence</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut({callbackUrl: '/'})">
            <v-list-item-title class="text-style-classic">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
</template>

<script>
import nuxtStorage from "nuxt-storage"
import project from "~/services/project"
import { useNotificationStore } from "~/stores/notification";
import { storeToRefs } from "pinia";
import { ref } from 'vue'
import { Notification } from "~/entity/Notification";

export default {
  async setup(props, context) {
    const {data, signOut} = useAuth()
    const notificationStore = useNotificationStore();
    const { isNewNotification } = storeToRefs(notificationStore)
    const notification = ref([])
    const storedNotif = ref([])
    const unseenNotif = ref(0)
    //nuxtStorage.localStorage.setData("notification", null, 1, 'd')
    storedNotif.value = nuxtStorage.localStorage.getData("notification")
    if (storedNotif.value && notificationStore.notification)
      unseenNotif.value = storedNotif.value.length - notificationStore.notification.length
    notificationStore.notification = []
    if (storedNotif.value) {      
      for (const notif of storedNotif.value) {
        notificationStore.addNotification(new Notification(notif))
      }
      notification.value = notificationStore.notification
    }
    watch(isNewNotification, async() => {
        if (isNewNotification.value) {
            notification.value = notificationStore.notification
            unseenNotif.value++
            nuxtStorage.localStorage.setData("notification", notification.value, 1, 'd')
            context.emit("notification", notification)
            notificationStore.setNewNotificationToTrue(false)
        }
    })
    return {
      user: data.value.user,
      notification: notification,
      unseenNotif,
      signOut: signOut
    }
  },
  props: {
    hideCardMenu: {
      type: Boolean,
      default: false,
    },
    welcomePage: {
      type: Boolean,
      default: false,
    },
    reload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      projectName: "",
      projectVersion: "",
      name: "",
      rdrawer: false,
    };
  },
  async mounted() {
    this.name = this.user.name;
    if (!this.hideCardMenu) {
      const loadedproject = await project.getProject(
        nuxtStorage.localStorage.getData("projectId")
      );
      if (loadedproject) {
        this.projectName = loadedproject.name;
        this.projectVersion = loadedproject.version;
      }
    }
    
  },
  watch: {
    async reload(val) {
      if (val) {
        const loadedproject = await project.getProject(
          nuxtStorage.localStorage.getData("projectId"));
        if (loadedproject) {
          this.projectName = loadedproject.name;
          this.projectVersion = loadedproject.version;
        }
        this.$emit('reloaded')
      }
    }
  },
  methods: {
    openRightSlider(type) {
      if (type == 'notification')
        this.unseenNotif = 0
      this.$emit('construct', type)
    },
  },
};
</script>
